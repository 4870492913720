import React, { FC, useEffect } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from "../auth/authContext";
import Moment from "moment";
import "moment-timezone";
import LocalDateTime from "./LocalDateTime";
import ButtonWrapper from "./ButtonWrapper";
import { Invoice, Transaction, useApi } from "../api/shareparkApi";
import ParkingDuration from "./ParkingDuration";
import { useQuery } from "@tanstack/react-query";

const Reports = () => {
  return (
    <Container className="px-5">
      <Row className="gx-2 my-5">
        <Col lg>
          <Transactions />
        </Col>
      </Row>
    </Container>
  );
};

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Transactions = () => {
  const { fetchTransactions } = useApi();

  const navigate = useNavigate();

  const { data: transactions, isLoading } = useQuery({
    queryKey: ["transactions"],
    queryFn: fetchTransactions,
  });

  let { id } = useParams();
  let transaction = transactions?.find((t) => t.id === id);

  return (
    <>
      {transaction ? (
        <TransactionDetails />
      ) : (
        <>
          <Row>
            <Col>
              <h2>Your Parking Sessions</h2>
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              {isLoading && <div>Loading...</div>}

              {transactions && transactions.length > 0 && (
                <div className="table-responsive">
                  <table className="table sharepark-table">
                    <thead>
                      <tr>
                        <th>Start</th>
                        <th>End</th>
                        <th>Duration</th>
                        <th>Site</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((t, i) => {
                        return (
                          <tr
                            key={i}
                            onClick={() => navigate(`/reports/${t.id}`)}
                            className={!t.endUtc ? "table-success" : ""}
                            style={{ cursor: "pointer" }}
                          >
                            <td>
                              <LocalDateTime date={t.startUtc} />
                            </td>
                            <td>
                              {t.endUtc ? (
                                <LocalDateTime date={t.endUtc} />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {t.endUtc ? (
                                <ParkingDuration duration={t.duration} />
                              ) : (
                                <ParkingDuration
                                  duration={
                                    (new Date().getTime() -
                                      new Date(t.startUtc + "Z").getTime()) /
                                    1000
                                  }
                                />
                              )}
                            </td>
                            <td>{t.siteName}</td>
                            <td>{t.type}</td>
                            <td>{formatter.format(t.amount)}</td>
                            <td>{t.status}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {transactions && transactions.length === 0 && (
                <div>You have no transactions</div>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const TransactionDetails = () => {
  const { fetchTransactions } = useApi();

  const navigate = useNavigate();

  const { data: transactions } = useQuery({
    queryKey: ["transactions"],
    queryFn: fetchTransactions,
  });

  let { id } = useParams();
  let transaction = transactions?.find((t) => t.id === id);
  let invoice = transaction?.invoices[0];

  const invoiceColor = (status: string) => {
    switch (invoice?.status) {
      case "paid":
        return "green";
      case "pending":
        return "orange";
      case "failed":
        return "red";
      default:
        return "black";
    }
  };

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <Modal show={true} size="xl">
      <Modal.Header style={{ backgroundColor: "white" }}>
        <Col>
          <h2>Transaction Details</h2>
        </Col>
        <Col xs={3} className="text-end">
          <ButtonWrapper
            buttonText="Close x"
            fullWidth={true}
            onClick={() => navigate("/reports")}
          />
        </Col>
      </Modal.Header>
      <Modal.Body>
        {transaction && (
          <Container>
            <h4>
              <LocalDateTime date={transaction.startUtc} /> -{" "}
              {transaction.siteName}
            </h4>

            <Row>
              <Col>
                <label style={{ color: "#fe9015" }}>Type</label>
                <p>{transaction.type}</p>
              </Col>
              <Col>
                <label style={{ color: "#fe9015" }}>Duration</label>
                <p>
                  {transaction.endUtc ? (
                    <ParkingDuration duration={transaction.duration} />
                  ) : (
                    <ParkingDuration
                      duration={
                        (new Date().getTime() -
                          new Date(transaction.startUtc + "Z").getTime()) /
                        1000
                      }
                    />
                  )}
                </p>
              </Col>
              <Col>
                <label style={{ color: "#fe9015" }}>Cost</label>
                <p>{formatter.format(transaction.amount)}</p>
              </Col>
              <Col>
                <label style={{ color: "#fe9015" }}>Status</label>
                <p>{transaction.status}</p>
              </Col>
            </Row>

            {invoice && (
              <Row>
                <Col>
                  <label style={{ color: "#fe9015" }}>Invoice</label>
                  <p>
                    {invoice.number}{" "}
                    <span style={{ color: invoiceColor(invoice.status) }}>
                      {invoice.status}
                    </span>
                    {invoice.url && (
                      <span
                        className="ms-4 btn btn-secondary"
                        onClick={() => window.open(invoice?.url, "_blank")}
                      >
                        Download
                      </span>
                    )}
                  </p>
                </Col>
              </Row>
            )}

            <h4>Events</h4>
            <table className="table sharepark-table">
              <thead>
                <tr>
                  <th>Date / Time</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {transaction.events.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {e.createdUtc &&
                          Moment.utc(e.createdUtc.substring(0, 19))
                            .tz(Moment.tz.guess())
                            .format("DD MMM YYYY HH:mm:ss")}
                      </td>
                      <td>{e.message}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Reports;
