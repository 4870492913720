import { FC, useEffect, useMemo, useState } from "react";
import { Reservation, useApi } from "../api/shareparkApi";
import {
  Col,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import "../custom.css";
import ButtonWrapper from "./ButtonWrapper";
import { useNavigate } from "react-router-dom";
import { Profile } from "../auth/authContext";
import { useForm, SubmitHandler } from "react-hook-form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactComponent as Electric } from "../images/electric.svg";
import { ReactComponent as Pram } from "../images/pram.svg";
import { ReactComponent as Accessible } from "../images/wheelchair.svg";
import { ReactComponent as Car } from "../images/car.svg";
import { toZonedTime } from "date-fns-tz";

function onlyUnique<T>(value: T, index: number, self: T[]): boolean {
  return self.indexOf(value) === index;
}

const inOneHour = new Date(Date.now() + 60 * 60 * 1000);

/**
 * Converts a date to a string in the format "YYYY-MM-DD"
 */
const toLocStrDate = (date: Date): string => {
  return date.toLocaleDateString("en-AU").split("/").reverse().join("-");
};

/**
 * Converts a date to a string in the format "HH:MM"
 */
const toLocStrTime = (date: Date): string => {
  return date
    .toLocaleTimeString(undefined, { hour12: false })
    .split(":")
    .slice(0, 2)
    .join(":");
};

// loop and create times for every 15 minutes
const times = [] as string[];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 15) {
    const hour = i < 10 ? "0" + i : i;
    const minute = j < 10 ? "0" + j : j;

    times.push(`${hour}:${minute}`);
  }
}

function filterTimesLaterThan(timeToFilter: number, timezone: string) {
  // Step 1: Get the current time in the target timezone

  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: timezone,
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  });
  const [currentHour, currentMinute] = formatter
    .formatToParts(timeToFilter)
    .filter((part) => part.type === "hour" || part.type === "minute")
    .map((part) => parseInt(part.value, 10));

  // Step 2: Filter times that are later than the current time
  return times.filter((time) => {
    const [hour, minute] = time.split(":").map(Number);

    // Compare times
    return (
      hour > currentHour || (hour === currentHour && minute > currentMinute)
    );
  });
}

const defaultValues: Reservation = {
  city: "",
  vehicleId: "",
  siteId: "",
  siteName: "",
  // set default date and time to australia date and time one hour from now
  startDate: toLocStrDate(new Date(Date.now())),
  startTime: toLocStrTime(new Date(Date.now())),

  // set end time to 1 hour from now
  endDate: toLocStrDate(inOneHour),
  endTime: toLocStrTime(inOneHour),

  status: "Pending",
  price: 0,
  bayType: "",
};

type AvailableCapacity = {
  siteId: string;
  error: string;
  status: string;
  price: number;
};

const ReservationDetails: FC<{
  reservation: Reservation;
  profile: Profile;
}> = ({ reservation, profile }) => {
  const navigate = useNavigate();
  const { createReservation, cancelReservation, getStatusAndPrice } = useApi();
  const queryClient = useQueryClient();

  const readOnly = useMemo(() => !!reservation?.id, [reservation?.id]);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm<Reservation>({ defaultValues: reservation, values: reservation });

  const startDate = watch("startDate") || defaultValues.startDate;
  const startTime = watch("startTime") || defaultValues.startTime;
  const endDate = watch("endDate") || defaultValues.endDate;
  const endTime = watch("endTime") || defaultValues.endTime;
  const vehicleId = watch("vehicleId") || defaultValues.vehicleId;

  const city = watch("city") || defaultValues.city;
  const siteId = watch("siteId") || defaultValues.siteId;
  const bayType = watch("bayType") || defaultValues.bayType;

  const startDateIsToday = useMemo(
    () => startDate === toLocStrDate(new Date(Date.now())),
    [startDate]
  );

  const start = useMemo(
    () => new Date(`${startDate} ${startTime}`),
    [startDate, startTime]
  );
  const end = useMemo(
    () => new Date(`${endDate} ${endTime}`),
    [endDate, endTime]
  );

  const site = useMemo(
    () => profile.publicSites.find((_) => _.siteId === siteId),
    [profile.publicSites, siteId]
  );

  const vehicle = useMemo(
    () => profile.vehicles.find((_) => _.id === vehicleId),
    [profile.vehicles, vehicleId]
  );

  const currentLocalTimeAtSite = useMemo(
    () => toZonedTime(new Date(), site?.siteTimeZone ?? "Australia/Sydney"),
    [site]
  );

  const isValidForFetching = useMemo(() => {
    const values = getValues();

    // check if values is empty object
    if (Object.keys(values).length === 0) {
      return false;
    }

    // check if all values are not empty
    const result =
      values &&
      city !== "" &&
      values.vehicleId !== "" &&
      siteId !== "" &&
      values.startDate !== "" &&
      values.startTime !== "" &&
      values.endDate !== "" &&
      values.endTime !== "" &&
      !readOnly &&
      start < end;

    return result;
  }, [getValues, readOnly, start, end, city, siteId]);

  useEffect(() => {
    if (readOnly) return;

    // if start + 1 hour is after end, set end to start + 1 hour (and the end time hasn't been changed already)
    if (
      start.getTime() + 60 * 60 * 1000 > end.getTime() &&
      !(isDirty && dirtyFields.endTime)
    ) {
      const newEnd = new Date(start.getTime() + 60 * 60 * 1000);
      setValue("endDate", newEnd.toISOString().split("T")[0]);
      setValue("endTime", newEnd.toTimeString().split(" ")[0].substring(0, 5));
    }
  }, [readOnly, start, end, isDirty, dirtyFields.endTime, setValue]);

  // when city changes, reset siteId
  useEffect(() => {
    setValue("siteId", "");
    setValue("bayType", "");
  }, [setValue, city]);

  const { data, isFetching } = useQuery(
    ["price", { city, siteId, start, end, bayType }],
    () =>
      getStatusAndPrice({
        city,
        siteId,
        bayType,
        endDate,
        endTime,
        startDate,
        startTime,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: isValidForFetching,
    }
  );

  useEffect(() => {
    if (data) {
      setValue("status", data.status);
      setValue("price", data.price);
      setValue("error", data.error);

      // setAvailableCapacity({
      //   siteId: lastValues.siteId,
      //   status: data.status,
      //   price: data.price,
      //   error: data.error,
      // });
    }
  }, [data, setValue]);

  const onSubmit: SubmitHandler<Reservation> = (data) => {
    createReservation(data).then(async () => {
      await queryClient.invalidateQueries(["reservations"]);
      navigate("/reservations");
    });
  };

  const cancel = () => {
    cancelReservation(reservation).then(async () => {
      await queryClient.invalidateQueries(["reservations"]);
      navigate("/reservations");
    });
  };

  const formatCurrency = (value: number | undefined) => {
    if (!value) return "";

    return value.toLocaleString("en-AU", {
      style: "currency",
      currency: "AUD",
      minimumFractionDigits: 2,
    });
  };

  const watchCity = watch("city") || reservation?.city;
  const price = watch("price") || reservation?.price;

  const canSelectTime = (time: string, type: string) => {
    const parseTime = (timeStr: string) => {
      const [hours, minutes] = timeStr.split(":").map(Number);
      const date = new Date();
      date.setHours(hours, minutes, 0, 0);
      return date;
    };

    if (type === "start") {
      if (startDateIsToday) {
        return parseTime(time) > currentLocalTimeAtSite;
      } else {
        return true;
      }
    }

    if (type === "end") {
      // check if end date is the same as start date
      if (startDate === endDate) {
        const startTime = parseTime(
          `${start.getHours()}:${start.getMinutes()}`
        );
        const endTime = parseTime(time);

        return endTime > startTime;
      } else {
        return true;
      }
    }
  };

  useEffect(() => {
    // if startDate > endDate, set endDate to startDate
    if (startDate && endDate && startDate > endDate) {
      setValue("endDate", startDate);
    }
  }, [startDate, endDate, setValue]);

  const toggleBayType = (type: string) => {
    if (type !== "") {
      if (!site || !site.bayTypes.includes(type)) {
        return;
      }
    }

    if (bayType === type) {
      setValue("bayType", "");
    } else {
      setValue("bayType", type);
    }
  };

  const formatTime = (time: string) => {
    // take in string like 12:00 and return 12:00 PM
    const hour = parseInt(time.split(":")[0]);
    const minute = parseInt(time.split(":")[1]);
    let minuteString = time.split(":")[1];

    // ensure leading zero on minute
    if (minute < 10) {
      minuteString = `0${minute}`;
    }

    if (hour < 12) {
      return `${hour}:${minuteString} AM`;
    }

    if (hour === 12) {
      return `${hour}:${minuteString} PM`;
    }

    return `${hour - 12}:${minuteString} PM`;
  };

  return (
    <Modal
      show={true}
      dialogClassName="modalReservationEditForm"
      centered
      backdrop="static"
    >
      <Modal.Header style={{ backgroundColor: "white" }}>
        <Col>
          <h2>Reservation</h2>
        </Col>
        <Col xs={3} className="text-end">
          <ButtonWrapper
            buttonText="Close x"
            fullWidth={true}
            onClick={() => navigate("/reservations")}
          />
        </Col>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} className="formStyle">
          <FormGroup>
            <FormLabel>City</FormLabel>
            <FormSelect
              {...register("city")}
              defaultValue={reservation?.city}
              disabled={readOnly}
            >
              <option value={""}>Choose a City</option>
              {profile.publicSites
                .map((site) => site.city)
                .filter(onlyUnique)
                .map((city, i) => {
                  return <option key={`city-${i}`}>{city}</option>;
                })}
            </FormSelect>
          </FormGroup>

          <FormGroup>
            <FormLabel>Vehicle</FormLabel>
            <FormSelect {...register("vehicleId")} disabled={readOnly}>
              {profile.vehicles.map((vehicle, i) => {
                return (
                  <option key={vehicle.id} value={vehicle.id}>
                    {vehicle.plate}
                  </option>
                );
              })}
            </FormSelect>
          </FormGroup>

          <FormGroup>
            <FormLabel>Site</FormLabel>
            <FormSelect {...register("siteId")} disabled={readOnly}>
              <option value={""}>Choose a Site</option>
              {profile.publicSites
                .filter((_) => _.city === watchCity)
                .map((site, i) => {
                  return (
                    <option key={site.siteId} value={site.siteId}>
                      {site.siteName}
                    </option>
                  );
                })}
            </FormSelect>
          </FormGroup>

          <Row>
            <Col>
              <FormGroup>
                <FormLabel>Bay Features</FormLabel>
                <div className="d-flex gap-2">
                  <div
                    className={
                      "w-100 d-flex justify-content-center flex-grow-1" +
                      (bayType === ""
                        ? " orangeButton"
                        : " orangeButton-outline")
                    }
                    onClick={() => toggleBayType("")}
                  >
                    <Car style={{ width: 24, marginRight: "0.5rem" }} />{" "}
                    Standard
                  </div>
                  <div
                    className={
                      "w-100 d-flex justify-content-center flex-grow-1" +
                      (bayType === "ev"
                        ? " orangeButton"
                        : " orangeButton-outline") +
                      (site &&
                      site.bayTypes.includes("ev") &&
                      vehicle?.isElectric
                        ? ""
                        : " disabledOption")
                    }
                    onClick={() => toggleBayType("ev")}
                  >
                    <Electric style={{ width: 24, marginRight: "0.5rem" }} /> EV
                  </div>
                  <div
                    className={
                      "w-100 d-flex justify-content-center flex-grow-1" +
                      (bayType === "pram"
                        ? " orangeButton"
                        : " orangeButton-outline") +
                      (site && site.bayTypes.includes("pram")
                        ? ""
                        : " disabledOption")
                    }
                    onClick={() => toggleBayType("pram")}
                  >
                    <Pram style={{ width: 24, marginRight: "0.5rem" }} /> Pram
                  </div>
                  <div
                    className={
                      "w-100 d-flex justify-content-center flex-grow-1" +
                      (bayType === "accessible"
                        ? " orangeButton"
                        : " orangeButton-outline") +
                      (site && site.bayTypes.includes("accessible")
                        ? ""
                        : " disabledOption")
                    }
                    onClick={() => toggleBayType("accessible")}
                  >
                    <Accessible style={{ width: 24, marginRight: "0.5rem" }} />{" "}
                    Accessible
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <FormLabel>Start Date</FormLabel>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={defaultValues.startDate}
                  disabled={readOnly}
                  min={toLocStrDate(new Date())}
                  {...register("startDate")}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>Start Time</FormLabel>
                {/* <input
                  className="form-control"
                  type="time"
                  defaultValue={defaultValues.startTime}
                  disabled={readOnly}
                  {...register("startTime")}
                /> */}

                <select
                  className="form-control"
                  {...register("startTime")}
                  disabled={readOnly}
                >
                  {times.map((time, i) => {
                    return (
                      <option
                        key={`time-${i}`}
                        value={time}
                        disabled={!canSelectTime(time, "start")}
                      >
                        {formatTime(time)}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <FormLabel>End Date</FormLabel>
                <input
                  className="form-control"
                  type="date"
                  defaultValue={defaultValues.endDate}
                  disabled={readOnly}
                  min={toLocStrDate(start)}
                  {...register("endDate")}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <FormLabel>End Time</FormLabel>
                {/* <input
                  className="form-control"
                  type="time"
                  defaultValue={defaultValues.endTime}
                  disabled={readOnly}
                  {...register("endTime")}
                /> */}

                <select
                  className="form-control"
                  {...register("endTime")}
                  disabled={readOnly}
                >
                  {times.map((time, i) => {
                    return (
                      <option
                        key={`time-${i}`}
                        value={time}
                        disabled={!canSelectTime(time, "end")}
                      >
                        {formatTime(time)}
                      </option>
                    );
                  })}
                </select>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <FormLabel>Status</FormLabel>
            {isFetching ? (
              <label className="status">Loading...</label>
            ) : (
              <label className="status">
                {(!readOnly && siteId === "") || data?.error ? (
                  <span className="error">{data?.error}&nbsp;</span>
                ) : (
                  getValues("status")
                )}
              </label>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>Price</FormLabel>
            {isFetching ? (
              <label className="status">Loading...</label>
            ) : (
              <label className="status">
                {(!readOnly && siteId === "") || data?.error ? (
                  <>&nbsp;</>
                ) : (
                  formatCurrency(price)
                )}
              </label>
            )}
          </FormGroup>

          {!readOnly && (
            <div className="mt-3">
              <input
                disabled={
                  !!getValues("error") || getValues("status") !== "Pending"
                }
                type="submit"
                className="orangeButton"
              />
            </div>
          )}
        </form>

        {readOnly && (
          <Col xs={8}>
            <ButtonWrapper
              buttonText="Cancel Reservation"
              fullWidth={true}
              onClick={() => cancel()}
            />
          </Col>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ReservationDetails;
